export default class previewCourse {
    constructor() {
        this.playPreviewVideos = $('.js-play-preview');

        this.modal = new bootstrap.Modal(document.getElementById('exampleModalToggle'), {
            keyboard: false
        })

        this.switchPricePlanBtn = $('.js-switch-tab');

        this.btnCloseModal = $('.js-btn-close');

        this.init();
    }


    togglePreviewVideo() {
        const _self = this;
        const preloadIframe = $('#preload_iframe');
        this.btnCloseModal.on('click', function (e) {
            _self.modal.toggle();
            $('.modal-body #target_iframe').html('');
            $('.modal-title').html('');
            preloadIframe.show();
            preloadIframe.addClass('d-flex');
        })

        this.playPreviewVideos.on('click', function (e) {
            const iframe = $(this).attr('data-iframe');
            const title = $(this).attr('data-title');
            $('.modal-body #target_iframe').html(iframe);
            $('.modal-body #target_iframe iframe').on('load', function () {
                console.log('loaded iframe!');
                preloadIframe.hide();
                preloadIframe.removeClass('d-flex');
            })
            $('.modal-title').html(title);
            _self.modal.toggle();

        })
    }

    switchPlanPricing() {
        const _self = this;
        this.switchPricePlanBtn.on('click', function (e) {
            const targetBlock = $(this).attr('data-target');
            const price = $(this).attr('data-price');
            console.log(price, 'price');
            $('[data-plan_price]').removeClass('shadow');
            $(`[data-plan_price="${price}"]`).addClass('shadow');
            $('.js-switch-tab').removeClass('fw-bold');
            $(this).addClass('fw-bold');
            $(this).addClass('border-1');

            $('.js-tab-content').hide();
            $(`${targetBlock}.js-tab-content`).show();
        })
    }

    init() {
        this.switchPlanPricing();
        this.togglePreviewVideo();

    }
}